import React from 'react';
import PropTypes from 'prop-types';

class CurrencyInput extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.url && this.props.button) {
            console.warn('Both a url and button prop were passed to the TextInput component');
        }
    }

    onFieldChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.props.onChange(name, value);
    }

    validateInput(e) {
        const target = e.target;
        var _countDecimals = 0;
        if (target.value.toString().includes(".")) {
            _countDecimals = target.value.toString().split(".")[1].length || 0;
        }
        const re = RegExp("^[0-9]+(.[0-9]{2})?$"); // was RegExp("^[0-9]+(\.[0-9]{2})?$");
        if ((!re.test(e.key) && e.key !== ".") || _countDecimals === 2) {
            e.preventDefault();
        }
    }

    render() {
        return (
            <div className={this.props.error ? 'ln-c-form-group has-error' : 'ln-c-form-group'}>
                {this.props.label ? <label htmlFor={this.props.name} className="ln-c-label">{this.props.label}{this.props.asterix ? <span className="asterix">*</span> : ''}</label> : ''}
                {this.props.error ? <div id="text-input-field-2Validation" className="ln-c-field-info ln-c-field-info--error" role="alert">{this.props.error}</div> : ''}
                <div className="ln-c-input-group">
                    <input
                        id={this.props.name}
                        type={"text"}
                        name={`${this.props.name}`}
                        placeholder={this.props.placeholder}
                        onChange={this.onFieldChange.bind(this)}
                        value={this.props.value}
                        className="ln-c-text-input ln-c-input-group__control"
                        onKeyPress={(e) => this.validateInput(e)}
                        autoComplete={`auto-${this.props.name}`}
                        disabled={this.props.disabled ? this.props.disabled : false}
                        max={this.props.max ? this.props.max : 999999999}
                    />
                </div>
            </div>
        )
    }

};

CurrencyInput.propTypes = {
    id: PropTypes.string.isRequired,
    alhpaNumeric: PropTypes.bool,
    button: PropTypes.func,
    url: PropTypes.string,
    error: PropTypes.string,
    link: PropTypes.string,
    pattern: PropTypes.instanceOf(RegExp),
    class: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    toUpper: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default CurrencyInput;