import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ConsoleAPI from '../../api/ConsoleAPI';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

function UnsubscribePage({ navigation }) {

  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const [unsubscribed, setUnsubscribed] = useState(false);

  const unsubscribe = () => {
    ConsoleAPI.unsubscribeUser(id, (data) => {
      console.log(data);
      setUnsubscribed(true);
    });
  }

  return (
    <div>

      <Header />

      <main id="main-content">
        <div className="owl-carousel header-slider">
          <div className="slide main-bg"></div>
        </div>

        <div className="row">
          <div className="small-12">
            <div className="content-frame">
              <div className="header-content">

                {unsubscribed ?
                  <>
                    <h2 className="text-center">You have unsubscribed!</h2>
                    <p className="text-center intro-title">We are sorry to see you go, but if you change your mind in the future, you can always opt back in when you place another order.  For now, it's goodbye.</p>
                  </>
                  :
                  <>
                    <h2 className="text-center">Do you really want to Unsubscribe?</h2>
                    <p className="text-center intro-title">Absolutely no problem if you do, just click the Unsubscribe Button below and we'll take care of that.<br />Note that you are unsubscribing from Business to Business marketing from Sainsbury's Argos Gift Card (encompassing Argos for Business and Sainsburys for Business, trading names of Argos Business Solutions).</p>

                    <p className="text-center">
                      <div onClick={unsubscribe} className={"ln-c-button ln-c-button--filled"} style={{ textDecoration: 'none', cursor: 'pointer' }}>Unsubscribe</div>
                    </p>
                  </>
                }

              </div>
            </div>
          </div>
        </div>

      </main>

      <Footer />

    </div>
  )

}


export default UnsubscribePage;

