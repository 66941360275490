import React from 'react';
import { withRouter, Link } from 'react-router-dom';

class BackButton extends React.Component {

    render() {
        return (
            <Link className="back" to={this.props.url}><i className="fas fa-chevron-left"></i>{this.props.title}</Link>
        )
    }
};

export default withRouter(BackButton);