import React from 'react';

class TextArea extends React.Component {

    onFieldChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">{this.props.label}</label>
                <textarea
                    type="text"
                    name={`${this.props.name}`}
                    placeholder={this.props.placeholder}
                    onChange={this.onFieldChange.bind(this)}
                    maxLength={this.props.maxLength ? this.props.maxLength : 300}
                    value={this.props.value}
                    className="form-control"
                    rows={this.props.rows ? this.props.rows : 3}
                />
                {this.props.error ? <div className="invalid-error">{this.props.error}</div> : ''}
            </div>
        )
    }

};

export default TextArea;