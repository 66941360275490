import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ConsoleAPI from '../../api/ConsoleAPI';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

function ConfirmationPage({ navigation }) {

  const { id } = useParams();
  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    loadOrder();
  }, []);

  const loadOrder = () => {
    ConsoleAPI.getOrderTracking(id, (data) => {
      console.log(data);
      if (data && data.totalCost) {
        setOrderId(data.orderId);
        /*
        window.dataLayer.push({
          event: 'purchase',
          value: data.totalCost.toFixed(2),
          id: data.orderId
        });
        */
      }
    });
  }

  return (
    <div>

      <Header />

      <main id="main-content">
        <div className="owl-carousel header-slider">
          <div className="slide main-bg"></div>
        </div>

        <div className="row">
          <div className="small-12">
            <div className="content-frame">
              <div className="header-content">
                <h2 className="text-center">Thank you for placing your order</h2>
                <p className="text-center intro-title">Your Order Number is</p>
                <h2 className="text-center">{orderId}</h2>
                <p className="text-center intro-title">Please keep this Order Number safe so that the team can help with any queries you may have with regards to your order.  <br />Also, please quote this Order Number when making payment. </p>
                <h3 className="text-center">What happens next...</h3>
                <p className="text-center intro-title">Within one hour we will generate a pro-forma invoice confirming all the relevant information and the total order value. This will also confirm the payment options available to you. Please check your junk and spam folder if this is not received.</p>
                <p className="text-center intro-title">Please check the details of your order before making payment. If any details are incorrect or you haven’t received a pro-forma invoice, please contact our Gifting Account Support Team at  <a href="mailto:afb.giftcards@sainsburys.co.uk">afb.giftcards@sainsburys.co.uk</a>.</p>
                <p className="text-center intro-title">Please include the above order number on your payment to ensure this is processed as quickly as possible.<br />
                                                       Once payment is received, we will despatch your order to you.</p>
                <p className="text-center intro-title">eGift Cards will be delivered to you within 1 working day of payment being received; these are delivered via email with a spreadsheet containing the eGift URL’s.<br />
                                                       An SMS passcode is sent to the mobile phone number provided on order placement and this is used to unlock the spreadsheet containing the eGift Card URLs.</p>
                <p className="text-center intro-title">Physical Gift Cards will be despatched within 2 working days of payment being received, delivered by our courier DPD. Gift Cards will be active on receipt and do not require activation.</p>
                <p className="text-center intro-title">If you have a question, please check out the FAQ’s page first <a href="https://www.sainsburysforbusiness.co.uk/frequently-asked-questions/">here</a>, as this may get you to an answer sooner.</p>
                <p className="text-center">
                  <a href="/" className={"ln-c-button ln-c-button--filled"}>Place another Order</a>
                </p>
              </div>
            </div>
          </div>
        </div>

      </main>

      <Footer />

    </div>
  )

}

export default ConfirmationPage;
