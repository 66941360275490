import React from 'react';

const SubmitButton = (props) => {
    const { text, secondary = false, full = false, onClick, disabled = false } = props;
    return (

        <button
            className={full ? "ln-c-button ln-c-button--filled ln-c-button--full" : secondary ? "ln-c-button ln-c-button--outlined" : "ln-c-button ln-c-button--filled"}
            disabled={disabled}
            onClick={onClick}>{text}</button>



    )
};

export default SubmitButton;