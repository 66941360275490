import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import { SubmitButton, SwitchButton } from "../../components/Button";
import { TextInput, CurrencyInput } from "../../components/TextInput";
import { SelectBox } from "../../components/SelectBox";
import ConsoleAPI from "../../api/ConsoleAPI";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { ReCaptcha } from "react-recaptcha-google";
import "react-alice-carousel/lib/alice-carousel.css";

function validateInput(input) {
  var error = "";
  let count = (input.match(/-/g) || []).length;
  if (input.includes("--")) {
    error = "Sequential hyphens not permitted";
  } else if (count > 4) {
    error = "Maximum of 4 hyphens permitted";
  }

  return error;
}

function replaceWordChars(text) {
  var s = text;
  // smart single quotes and apostrophe
  s = s.replace(/[\u2018|\u2019|\u201A]/g, "'");
  // smart double quotes
  s = s.replace(/[\u201C|\u201D|\u201E]/g, '"');
  // ellipsis
  s = s.replace(/\u2026/g, "...");
  // dashes
  s = s.replace(/[\u2013|\u2014]/g, "-");
  // circumflex
  s = s.replace(/\u02C6/g, "^");
  // open angle bracket
  s = s.replace(/\u2039/g, "<");
  // close angle bracket
  s = s.replace(/\u203A/g, ">");
  // spaces
  s = s.replace(/[\u02DC|\u00A0]/g, " ");
  return s;
}

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

// Christmas Cards
/*

    <div className="card">
        <div className="card-image">
            <img alt="" src="/assets/card-sainsburys-xmas.png" />
        </div>
        <div className="card-content">
            <h5 className="text-center">Sainsburys<br />eGift Card</h5>
            <p>Treat your employees to Christmas dinner and more. They can use this eGift Card to shop 1000s of options at Sainsbury's from food to clothing instore.</p>
        </div>
    </div>,
    <div className="card">
        <div className="card-image">
            <img alt="" src="/assets/card-argos-xmas.png" />
        </div>
        <div className="card-content">
            <h5 className="text-center">Argos<br />eGift Card</h5>
            <p>The perfect Christmas reward. Treat them to 1000s of options, from tech to homewares and more. This eGift Card can be used online and in-store.</p>
        </div>
    </div>,
*/

const items = [
  <div className="card">
  <div className="card-image">
    <img alt="" src="/assets/card-multi.png" />
  </div>
  <div className="card-content">
    <h5 className="text-center">
      Multi Brand
      <br />
      eGift Card
    </h5>
    <p>
    One card. Bags of choice. Our Multi Brand eGift Card can be used online or instore across all of our much-loved brands - Sainsbury's, Argos, Tu and Habitat.
    </p>
  </div>
  </div>,
  <div className="card">
    <div className="card-image">
      <img alt="" src="/assets/card-sainsburys.png" />
    </div>
    <div className="card-content">
      <h5 className="text-center">
        Sainsburys Physical
        <br />
        Gift Card
      </h5>
      <p>
        Accepted in 1,400 stores across the UK. This Gift Card is the perfect
        way to help them restock their cupboards or pick up something special.
      </p>
    </div>
  </div>,
  <div className="card">
    <div className="card-image">
      <img alt="" src="/assets/card-argos.png" />
    </div>
    <div className="card-content">
      <h5 className="text-center">
        Argos Physical
        <br />
        Gift Card
      </h5>
      <p>
        With 1000s of options to choose from - treat them to Argos' wide range
        of toys, tech and tonnes more.
      </p>
    </div>
  </div>,
  <div className="card">
    <div className="card-image">
      <img alt="" src="/assets/card-sainsburys-grocery-egift.png" />
    </div>
    <div className="card-content">
      <h5 className="text-center">
        Sainsburys Groceries
        <br />
        eGift Card
      </h5>
      <p>
        Buy to give to those who need it most with Gift Cards for food,
        toiletries and everyday essentials.
      </p>
    </div>
  </div>,
  <div className="card">
    <div className="card-image">
      <img alt="" src="/assets/card-argos-egift.png" />
    </div>
    <div className="card-content">
      <h5 className="text-center">
        Argos
        <br />
        eGift Card
      </h5>
      <p>
        The perfect pick for Argos fans. This eGift Card can be used online and
        in-store across a range of toys, tech, homewares, furniture and more.
      </p>
    </div>
  </div>,
  <div className="card">
    <div className="card-image">
      <img alt="" src="/assets/card-sainsburys-egift.png" />
    </div>
    <div className="card-content">
      <h5 className="text-center">
        Sainsburys
        <br />
        eGift Card
      </h5>
      <p>
        Brighten up their next trip to Sainsbury's. This eGift Card lets them
        shop food, clothing, beauty products, and more both online and instore.
      </p>
    </div>
  </div>,
  <div className="card">
    <div className="card-image">
      <img alt="" src="/assets/card-habitat-egift.png" />
    </div>
    <div className="card-content">
      <h5 className="text-center">
        Habitat
        <br />
        eGift Card
      </h5>
      <p>
        If home is their favourite place to be, they'll love this. This eGift
        Card can be used online at habitat.co.uk on homewares and furniture.
      </p>
    </div>
  </div>,
];

const Carousel = () => (
  <AliceCarousel
    items={items}
    responsive={responsive}
    disableButtonsControls={false}
    controlsStrategy="responsive"
    infinite={true}
    renderPrevButton={() => {
      return (
        <div className="carousel-left">
          <img alt="" src="/assets/left-arrow.png" />
        </div>
      );
    }}
    renderNextButton={() => {
      return (
        <div className="carousel-right">
          <img alt="" src="/assets/right-arrow.png" />
        </div>
      );
    }}
  />
);

class OrderPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      page: 1,
      id: "",
      copyAddress: false,
      apiError: "",
      clientName: "",
      clientPO: "",
      accountNumber: "",
      contactName: "",
      contactEmail: "",
      contactLine1: "",
      contactLine2: "",
      contactTown: "",
      contactPostcode: "",
      recipientName: "",
      recipientEmail: "",
      recipientLine1: "",
      recipientLine2: "",
      recipientTown: "",
      recipientPostcode: "",
      recipientTel: "",
      recipientCompany: "",
      charityNumber: "",
      contactTel: "",
      hearUs: "",
      hearUsOther: "",
      hasErrors: false,
      errClientName: "",
      errClientPO: "",
      errAccountNumber: "",
      errCharityNumber: "",
      errContactName: "",
      errContactEmail: "",
      errContactLine1: "",
      errContactLine2: "",
      errContactTown: "",
      errContactPostcode: "",
      errRecipientName: "",
      errCaptcha: "",
      errRecipientEmail: "",
      errRecipientLine1: "",
      errRecipientLine2: "",
      errRecipientTown: "",
      errRecipientPostcode: "",
      errHearUs: "",
      errHearUsOther: "",
      errRecipientTel: "",
      errContactTel: "",
      errOrderItems: "",
      contactCountry: "GB",
      recipientCountry: "GB",
      errContactCountry: "",
      errRecipientCountry: "",
      saving: false,
      orderItems: [],
      updates: false,
      terms: false,
      optOut: false,
      cardBrand: "",
      cardBrands: [
        {
          value: "",
          label: "Select...",
        },
        {
          value: "argos",
          label: "Argos",
        },
        {
          value: "sainsburys",
          label: "Sainsbury’s",
        },
        {
          value: "habitat",
          label: "Habitat",
        },
        {
          value: "multi",
          label: "Multi Brand",
        },
      ],
      cardOptionsArgos: [
        {
          value: "",
          label: "Select...",
        },
        {
          value: "7",
          label: "Argos eGift Card",
        },
        {
          value: "8",
          label: "Argos Physical Gift Card",
        },
        {
          value: "9",
          label: "Argos Wallets - Free",
        },
      ],
      //, {
      //    value: '13',
      //    label: 'Argos Seasonal eGift Card'
      //}],
      cardOptionsHabitat: [
        {
          value: "",
          label: "Select...",
        },
        {
          value: "11",
          label: "Habitat eGift Card",
        },
      ],
      cardOptionsMulti: [
        {
          value: "",
          label: "Select...",
        },
        {
          value: "17",
          label: "Multi Brand eGift Card",
        },
      ],
      cardOptionsSainsburys: [
        {
          value: "",
          label: "Select...",
        },
        {
          value: "1",
          label: "Sainsbury’s eGift Card",
        },
        {
          value: "2",
          label: "Sainsbury’s Physical Gift Card",
        },
        {
          value: "3",
          label: "Sainsbury’s £5 Physical Gift Card",
        },
        {
          value: "4",
          label: "Sainsbury’s £10 Physical Gift Card",
        },
        {
          value: "5",
          label: "Sainsbury’s £20 Physical Gift Card",
        },
        {
          value: "6",
          label: "Sainsbury’s Wallets - Free",
        },
        {
          value: "10",
          label: "Sainsbury’s Groceries eGift Card",
        },
      ],
      //, {
      //    value: '12',
      //    label: 'Sainsbury’s Seasonal eGift Card'
      //}],
      walletLines: [],
      cardType: "",
      errCardType: "",
      cardValue: 0,
      cardQuantity: 0,
      errTerms: "",
      allowCardPayments: false,
      recaptchaToken: "",
      loading: false,
      isBusiness: false,
      discounts: [],
      showDiscounts: false,
    };

    this.save = this.save.bind(this);
    this.back = this.back.bind(this);
    this.step2 = this.step2.bind(this);
    this.step3 = this.step3.bind(this);
    this.addCard = this.addCard.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.onToggleTerms = this.onToggleTerms.bind(this);
    this.onToggleMarketing = this.onToggleMarketing.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.onToggleIsBusiness = this.onToggleIsBusiness.bind(this);
    this.hideDiscounts = this.hideDiscounts.bind(this);
    this.loadDiscounts = this.loadDiscounts.bind(this);
  }

  hideDiscounts() {
    this.setState({
      showDiscounts: false,
    });
  }

  loadDiscounts() {
    console.log("loadDiscounts");
    ConsoleAPI.getDiscounts((data) => {
      console.log(data);
      this.setState({
        discounts: data,
        showDiscounts: true,
      });
    });
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  verifyCallback(recaptchaToken) {
    this.setState({
      recaptchaToken: recaptchaToken,
      errCaptcha: "",
    });
  }

  onToggle() {
    this.setState({
      updates: !this.state.updates,
    });
  }

  onToggleTerms(event) {
    event.preventDefault();
    this.setState({
      terms: !this.state.terms,
      errTerms: "",
    });
  }

  onToggleIsBusiness(event) {
    event.preventDefault();
    this.setState({
      isBusiness: !this.state.isBusiness,
    });
  }

  onToggleMarketing(event) {
    event.preventDefault();
    this.setState({
      optOut: !this.state.optOut,
    });
  }

  onChange(name, value) {
    this.setState(
      {
        [name]: value,
        hasErrors: false,
        apiError: "",
        errClientName: "",
        errClientPO: "",
        errAccountNumber: "",
        errContactName: "",
        errContactEmail: "",
        errContactLine1: "",
        errContactLine2: "",
        errContactTown: "",
        errContactPostcode: "",
        errRecipientName: "",
        errRecipientCompany: "",
        errRecipientEmail: "",
        errRecipientLine1: "",
        errRecipientLine2: "",
        errRecipientTown: "",
        errRecipientPostcode: "",
        errContactTel: "",
        errOrderItems: "",
        errRecipientCountry: "",
        errContactCountry: "",
        errRecipientTel: "",
        errCampaignID: "",
        errDiscountRate: "",
        errSainsburysDiscountRate: "",
        errHabitatDiscountRate: "",
        errHearUs: "",
        errHearUsOther: "",
        errCardType: "",
        errTerms: "",
        errCaptcha: "",
      },
      () => {
        if (name === "cardBrand") {
          this.setState({
            cardType: "",
          });
        }
      }
    );
  }

  addWallets(_lineId, _type, _qty) {
    /*
        _itemName = 'Sainsbury’s Wallets – Free';
                _cardValue = 0;
                _itemType = 6;
    _itemName = 'Argos Wallets – Free';
    _cardValue = 0;
    _itemType = 3;
    */
    this.setState({
      orderItems: this.state.orderItems.concat([
        {
          lineId: _lineId,
          ID: 0,
          itemName:
            _type === 6 ? "Sainsbury's Wallets – Free" : "Argos Wallets – Free",
          itemType: _type,
          qty: _qty,
          value: 0,
        },
      ]),
    });
  }

  addCard() {
    if (parseInt(this.state.cardType) === "") {
      this.setState({
        errCardType: "Please select a product type",
      });
    } else if (
      this.state.cardQuantity === "" ||
      parseInt(this.state.cardQuantity) <= 0
    ) {
      this.setState({
        errOrderItems: "Card Quantity must be greater than zero",
      });
    } else if (
      (this.state.cardType === "1" ||
        this.state.cardType === "2" ||
        this.state.cardType === "7" ||
        this.state.cardType === "8" ||
        this.state.cardType === "10" ||
        this.state.cardType === "11" ||
        this.state.cardType === "12" ||
        this.state.cardType === "13" ||
        this.state.cardType === "17") &&
      (this.state.cardValue === "" || parseFloat(this.state.cardValue) <= 0)
    ) {
      this.setState({
        errOrderItems: "Card Value must be greater than zero",
      });
    } else if (parseFloat(this.state.cardValue) > 2000) {
      this.setState({
        errOrderItems: "The maximum Unit Value is £2,000",
      });
    } else if (
      this.state.cardType === "11" &&
      parseFloat(this.state.cardValue) > 500
    ) {
      this.setState({
        errOrderItems: "The maximum Habitat Unit Value is £500",
      });
    } else if (
      this.state.cardType === "17" &&
      parseFloat(this.state.cardValue) > 750
    ) {
      this.setState({
        errOrderItems: "The maximum Multi Brand Unit Value is £750",
      });
    } else {
      var _itemType = parseInt(this.state.cardType);
      var _itemName = "";
      var _cardValue = parseFloat(this.state.cardValue);

      if (parseInt(this.state.cardType) === 1) {
        _itemName = "Sainsbury’s eGift Card";
        _itemType = 4;
      } else if (parseInt(this.state.cardType) === 2) {
        _itemName = "Sainsbury’s Physical Gift Card";
        _itemType = 5;
      } else if (parseInt(this.state.cardType) === 3) {
        _itemName = "Sainsbury’s £5 Physical Gift Card";
        _cardValue = 5;
        _itemType = 7;
      } else if (parseInt(this.state.cardType) === 4) {
        _itemName = "Sainsbury’s £10 Physical Gift Card";
        _cardValue = 10;
        _itemType = 8;
      } else if (parseInt(this.state.cardType) === 5) {
        _itemName = "Sainsbury’s £20 Physical Gift Card";
        _cardValue = 20;
        _itemType = 9;
      } else if (parseInt(this.state.cardType) === 6) {
        _itemName = "Sainsbury’s Wallets – Free";
        _cardValue = 0;
        _itemType = 6;
      } else if (parseInt(this.state.cardType) === 7) {
        _itemName = "Argos eGift Card";
        _itemType = 2;
      } else if (parseInt(this.state.cardType) === 8) {
        _itemName = "Argos Physical Gift Card";
        _itemType = 1;
      } else if (parseInt(this.state.cardType) === 9) {
        _itemName = "Argos Wallets – Free";
        _cardValue = 0;
        _itemType = 3;
      } else if (parseInt(this.state.cardType) === 11) {
        _itemName = "Habitat eGift Card";
        _itemType = 11;
      } else if (parseInt(this.state.cardType) === 10) {
        _itemName = "Sainsbury’s Groceries eGift Card";
        _itemType = 10;
      } else if (parseInt(this.state.cardType) === 12) {
        _itemName = "Sainsbury’s Seasonal eGift Card";
        _itemType = 12;
      } else if (parseInt(this.state.cardType) === 13) {
        _itemName = "Argos Seasonal eGift Card";
        _itemType = 13;
      } else if (parseInt(this.state.cardType) === 17) {
        _itemName = "Multi Brand eGift Card";
        _itemType = 17;
      }

      this.setState({
        orderItems: this.state.orderItems.concat([
          {
            lineId: crypto.randomUUID(),
            ID: 0,
            itemName: _itemName,
            itemType: _itemType,
            qty: this.state.cardQuantity,
            value: _cardValue,
          },
        ]),
        cardValue: 0,
        cardQuantity: 0,
        cardType: "",
        errOrderItems: "",
        cardBrand: "",
      });
    }
  }

  deleteRow(_index) {
    this.setState({
      orderItems: this.state.orderItems.filter(
        (item, index) => index !== _index
      ),
    });
  }

  back(page) {
    window.scrollTo(0, 0);
    this.setState({
      page: page,
    });
  }

  step2() {
    this.setState(
      {
        loading: true,
      },
      () => {
        let payload = {
          ID: "new",
          items: this.state.orderItems,
        };

        console.log(payload);

        ConsoleAPI.validateOrder(payload, (data) => {
          if (data) {
            this.setState(
              {
                validatedOrderItems: data.items,
                argosDiscountRate: data.argosDiscountRate,
                argosTotal: data.argosTotal,
                sainsburysDiscountRate: data.sainsburysDiscountRate,
                sainsburysTotal: data.sainsburysTotal,
                habitatDiscountRate: data.habitatDiscountRate,
                habitatTotal: data.habitatTotal,
                multiDiscountRate: data.multiDiscountRate,
                multiTotal: data.multiTotal,
                argosCustomDiscount: data.argosCustomDiscount,
                sainsburysCustomDiscount: data.sainsburysCustomDiscount,
                habitatCustomDiscount: data.habitatCustomDiscount,
                allowCardPayments: data.allowCardPayments,
                page: 2,
                loading: false,
                apiError: "",
              },
              () => {
                window.scrollTo(0, 0);
                let argosCost =
                  data.argosTotal -
                  Number(
                    Math.round(
                      (data.argosTotal / 100) *
                        parseFloat(data.argosDiscountRate).toFixed(2) +
                        "e2"
                    ) + "e-2"
                  );
                let sainsburysCost =
                  data.sainsburysTotal -
                  Number(
                    Math.round(
                      (data.sainsburysTotal / 100) *
                        parseFloat(data.sainsburysDiscountRate).toFixed(2) +
                        "e2"
                    ) + "e-2"
                  );
                let habitatCost =
                  data.habitatTotal -
                  Number(
                    Math.round(
                      (data.habitatTotal / 100) *
                        parseFloat(data.habitatDiscountRate).toFixed(2) +
                        "e2"
                    ) + "e-2"
                  );
                  let multiCost =
                  data.multiTotal -
                  Number(
                    Math.round(
                      (data.multiTotal / 100) *
                        parseFloat(data.multiDiscountRate).toFixed(2) +
                        "e2"
                    ) + "e-2"
                  );

                if (argosCost + sainsburysCost + habitatCost > 2500) {
                  this.setState({
                    cardPayments: false,
                    cardPaymentsDisabled: true,
                  });
                } else {
                  this.setState({
                    cardPaymentsDisabled: false,
                  });
                }
              }
            );
          } else {
            this.setState({
              apiError: "failed",
              loading: false,
            });
          }
        });
      }
    );
  }

  step3() {
    window.scrollTo(0, 0);
    this.setState({
      page: 3,
    });
  }

  save() {
    var _ok = true;
    //var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.clientName === "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errClientName: "Please enter an Organisation/Company Name",
      });
    } else if (validateInput(this.state.clientName) !== "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errClientName: validateInput(this.state.clientName),
      });
    }
    if (
      this.state.contactTel === "" ||
      (this.state.contactTel &&
        (this.state.contactTel.trim().length !== 11 ||
          this.state.contactTel.substring(0, 2) !== "07"))
    ) {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactTel: "Please enter a valid mobile phone number",
      });
    }

    if (!this.state.terms) {
      _ok = false;
      this.setState({
        hasErrors: true,
        errTerms: "Please accept the terms and conditions",
      });
    }
    if (this.state.recaptchaToken === "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errCaptcha: "Please complete the reCAPTCHA challenge",
      });
    }

    /*
        if (this.state.clientPO === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errClientPO: 'Please enter the client PO/Reference'
            });
        } else if (validateInput(this.state.clientPO) !== '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errClientPO: validateInput(this.state.clientPO)
            });
        }
        */

    if (
      this.state.charityNumber !== "" &&
      validateInput(this.state.charityNumber) !== ""
    ) {
      _ok = false;
      this.setState({
        hasErrors: true,
        errCharityNumber: validateInput(this.state.charityNumber),
      });
    }
    if (this.state.contactName === "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactName: "Please enter the contact name",
      });
    } else if (validateInput(this.state.contactName) !== "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactName: validateInput(this.state.contactName),
      });
    }
    if (this.state.contactEmail === "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactEmail: "Please enter the contact email",
      });
    } else if (!re.test(this.state.contactEmail)) {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactEmail: "Please enter a valid email address",
      });
    } else if (validateInput(this.state.contactEmail) !== "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactEmail: validateInput(this.state.contactEmail),
      });
    }

    if (this.state.contactLine1 === "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactLine1: "Please enter the contact address",
      });
    } else if (validateInput(this.state.contactLine1) !== "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactLine1: validateInput(this.state.contactLine1),
      });
    }

    if (
      this.state.contactLine2 !== "" &&
      validateInput(this.state.contactLine2) !== ""
    ) {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactLine2: validateInput(this.state.contactLine2),
      });
    }

    if (this.state.contactTown === "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactTown: "Please enter the contact town",
      });
    } else if (validateInput(this.state.contactTown) !== "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactTown: validateInput(this.state.contactTown),
      });
    }

    if (this.state.contactPostcode === "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactPostcode: "Please enter the contact postcode",
      });
    } else if (validateInput(this.state.contactPostcode) !== "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactPostcode: validateInput(this.state.contactPostcode),
      });
    }

    if (this.state.contactCountry === "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errContactCountry: "Please select the contact country",
      });
    }

    if (this.state.hearUs === "") {
      _ok = false;
      this.setState({
        hasErrors: true,
        errHearUs: "Please select an option",
      });
    } else if (
      this.state.hearUs === "other" &&
      this.state.hearUsOther.trim() === ""
    ) {
      _ok = false;
      this.setState({
        hasErrors: true,
        errHearUsOther: "Please enter how you heard about us",
      });
    } else if (
      this.state.hearUs === "other" &&
      validateInput(this.state.hearUsOther) !== ""
    ) {
      _ok = false;
      this.setState({
        hasErrors: true,
        errHearUsOther: validateInput(this.state.hearUsOther),
      });
    }

    if (!this.state.copyAddress) {
      if (!this.state.copyAddress && this.state.recipientName === "") {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientName: "Please enter the recipient name",
        });
      } else if (
        !this.state.copyAddress &&
        validateInput(this.state.recipientName) !== ""
      ) {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientName: validateInput(this.state.recipientName),
        });
      }

      if (!this.state.copyAddress && this.state.recipientCompany === "") {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientCompany:
            "Please enter the recipient Organisation/Company Name",
        });
      } else if (
        !this.state.copyAddress &&
        validateInput(this.state.recipientCompany) !== ""
      ) {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientCompany: validateInput(this.state.recipientCompany),
        });
      }

      if (!this.state.copyAddress && this.state.recipientEmail === "") {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientEmail: "Please enter the recipient email",
        });
      } else if (
        !this.state.copyAddress &&
        !re.test(this.state.recipientEmail)
      ) {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientEmail: "Please enter a valid email address",
        });
      } else if (
        !this.state.copyAddress &&
        validateInput(this.state.recipientEmail) !== ""
      ) {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientEmail: validateInput(this.state.recipientEmail),
        });
      }

      if (!this.state.copyAddress && this.state.recipientLine1 === "") {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientLine1: "Please enter the recipient address",
        });
      } else if (
        !this.state.copyAddress &&
        validateInput(this.state.recipientLine1) !== ""
      ) {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientLine1: validateInput(this.state.recipientLine1),
        });
      }

      if (
        !this.state.copyAddress &&
        this.state.recipientLine2 !== "" &&
        validateInput(this.state.recipientLine2) !== ""
      ) {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientLine2: validateInput(this.state.recipientLine2),
        });
      }

      if (!this.state.copyAddress && this.state.recipientTown === "") {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientTown: "Please enter the recipient town",
        });
      } else if (
        !this.state.copyAddress &&
        validateInput(this.state.recipientTown) !== ""
      ) {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientTown: validateInput(this.state.recipientTown),
        });
      }

      if (!this.state.copyAddress && this.state.recipientPostcode === "") {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientPostcode: "Please enter the recipient postcode",
        });
      } else if (
        !this.state.copyAddress &&
        validateInput(this.state.recipientPostcode) !== ""
      ) {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientPostcode: validateInput(this.state.recipientPostcode),
        });
      }

      if (!this.state.copyAddress && this.state.recipientCountry === "") {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientCountry: "Please select the recipient country",
        });
      }

      if (
        this.state.recipientTel === "" ||
        (this.state.recipientTel &&
          (this.state.recipientTel.trim().length !== 11 ||
            this.state.recipientTel.substring(0, 2) !== "07"))
      ) {
        _ok = false;
        this.setState({
          hasErrors: true,
          errRecipientTel: "Please enter a valid mobile phone number",
        });
      }
    }

    if (_ok) {
      this.setState({
        saving: true,
        loading: true,
      });

      /*
            let orderId = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
            */

      let orderId = crypto.randomUUID();

      let payload = {
        ID: orderId,
        addedBy: "00000000-0000-0000-0000-000000000000",
        clientName: replaceWordChars(this.state.clientName),
        clientPO: replaceWordChars(this.state.clientPO),
        contactName: replaceWordChars(this.state.contactName),
        contactEmail: replaceWordChars(this.state.contactEmail),
        contactLine1: replaceWordChars(this.state.contactLine1),
        contactLine2: replaceWordChars(this.state.contactLine2),
        contactTown: replaceWordChars(this.state.contactTown),
        contactPostcode: replaceWordChars(this.state.contactPostcode),
        contactCountry: this.state.contactCountry,
        contactTel: replaceWordChars(this.state.contactTel),
        recipientName: replaceWordChars(this.state.recipientName),
        recipientEmail: replaceWordChars(this.state.recipientEmail),
        recipientLine1: replaceWordChars(this.state.recipientLine1),
        recipientLine2: replaceWordChars(this.state.recipientLine2),
        recipientTown: replaceWordChars(this.state.recipientTown),
        recipientPostcode: replaceWordChars(this.state.recipientPostcode),
        recipientCountry: this.state.recipientCountry,
        recipientTel: replaceWordChars(this.state.recipientTel),
        recipientCompany: replaceWordChars(this.state.recipientCompany),
        copyAddress: this.state.copyAddress,
        cardPayments: this.state.allowCardPayments,
        campaignID: "",
        argosDiscountRate:
          this.state.argosDiscountRate === ""
            ? 0
            : this.state.argosDiscountRate,
        sainsburysDiscountRate:
          this.state.sainsburysDiscountRate === ""
            ? 0
            : this.state.sainsburysDiscountRate,
        habitatDiscountRate:
          this.state.habitatDiscountRate === ""
            ? 0
            : this.state.habitatDiscountRate,
            multiDiscountRate:
              this.state.multiDiscountRate === ""
                ? 0
                : this.state.multiDiscountRate,
        accountManager: "",
        items: this.state.orderItems.filter((d) => d.itemType !== 99),
        captchaToken: this.state.recaptchaToken,
        charityNumber: replaceWordChars(this.state.charityNumber),
        hearUs:
          this.state.hearUs === "other"
            ? replaceWordChars(this.state.hearUsOther)
            : this.state.hearUs,
        optOut: this.state.optOut,
      };

      var _gaOrderData = [];

      for (let i = 0; i < this.state.orderItems.length; i++) {
        let item = this.state.orderItems[i];
        _gaOrderData.push({
          price: item.value,
          quantity: item.qty,
        });
      }

      try {
        ConsoleAPI.updOneOffOrder(payload, (data) => {
          if (data) {
            this.setState(
              {
                saving: false,
                loading: false,
                //page: 4,
              },
              () => {
                if (data && data.status === "OK") {
                  window.scrollTo(0, 0);
                  this.props.history.push(`/confirmation/${orderId}`);
                } else {
                  this.setState({
                    apiError: data.message,
                    loading: false,
                  });
                }
              }
            );
          } else {
            this.setState({
              apiError: "failed",
              loading: false,
            });
          }
        });
      } catch (e) {
        this.setState({
          apiError: "failed",
          loading: false,
        });
      }
    }
  }

  render() {
    const sum = this.state.orderItems
      .filter((d) => d.itemType !== 99)
      .map((v) => v.value * v.qty)
      .reduce((sum, current) => parseFloat(sum) + parseFloat(current), 0);
    const argosDiscount = Number(
      Math.round(
        (this.state.argosTotal / 100) *
          parseFloat(this.state.argosDiscountRate).toFixed(2) +
          "e2"
      ) + "e-2"
    );
    const sainsburysDiscount = Number(
      Math.round(
        (this.state.sainsburysTotal / 100) *
          parseFloat(this.state.sainsburysDiscountRate).toFixed(2) +
          "e2"
      ) + "e-2"
    );
    const habitatDiscount = Number(
      Math.round(
        (this.state.habitatTotal / 100) *
          parseFloat(this.state.habitatDiscountRate).toFixed(2) +
          "e2"
      ) + "e-2"
    );
    const multiDiscount = Number(
      Math.round(
        (this.state.multiTotal / 100) *
          parseFloat(this.state.multiDiscountRate).toFixed(2) +
          "e2"
      ) + "e-2"
    );
    const discountedTotal = parseFloat(
      sum - argosDiscount - sainsburysDiscount - habitatDiscount - multiDiscount
    );

    return (
      <div>
        <Header />

        <main id="main-content">
          <div className="owl-carousel header-slider">
            <div className="slide main-bg"></div>
          </div>

          <div className="row">
            <div className="small-12">
              <div className="content-frame">
                {this.state.page === 1 && (
                  <div className="header-content">
                    <h2 className="text-center">
                      Sainsbury's & Argos Business Gift Card Ordering
                    </h2>
                    <h1 className="text-center">
                      This order website is for the purchase of Sainsbury's,
                      Argos and Habitat
                      <br />
                      Gift Cards for businesses, local authorities, charities
                      and schools.
                    </h1>

                    <div className="row">
                      <div className="small-12 medium-6 image-cell">
                        <img
                          alt=""
                          src="/assets/image-four-cards.png"
                        />
                      </div>
                      <div className="small-12 medium-6 text-cell">
                        <div className="text-wrap">
                          <h5>Ordering is simple</h5>
                          <p>
                            Follow the steps below and your Gift Card order will
                            be with you within 2 working days once we receive
                            your payment. Digital orders are received within 24
                            hours. Non business customers can purchase
                            Sainsburys Gift Cards{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://sainsburysgiftcard.co.uk/"
                            >
                              here
                            </a>{" "}
                            and Argos Gift Cards{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://argosgiftcard.co.uk/"
                            >
                              here
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-6 text-cell">
                        <div className="text-wrap">
                          <h5>What happens after I've placed my order?</h5>
                          <p>
                            You will receive a confirmation email with details
                            on how to make payment. Your order will be
                            dispatched once payment has been received. Please
                            keep the order number safe and quote this if you
                            have any queries with your order to{" "}
                            <a href="mailto:afb.giftcards@sainsburys.co.uk">
                              afb.giftcards@sainsburys.co.uk
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="small-12 medium-6 image-cell">
                        <img alt="" src="/assets/image-at-laptop.png" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 cards-title">
                        <h1>Gift Cards</h1>
                      </div>
                    </div>
                    <div className="carousel-row">
                      <Carousel />
                    </div>
                  </div>
                )}
                <div className="step-wrap">
                  {this.state.page === 1 && (
                    <div>
                      <div className="order-form-wrap">
                        <div className="row">
                          <div className="small-12">
                            <div className="order-form">
                              <div className="row">
                                <div className="small-12">
                                  <div className="content-panel">
                                    <h3 className="header h3">
                                      Step 1 - Choose your Brand and Gift Cards
                                    </h3>
                                    <p>
                                      Sainsbury's and Argos both offer physical
                                      and eGift Cards which can be loaded with
                                      any value between £1.00 - £2,000.00
                                    </p>
                                    <p>Multi Brand eGift Cards can be loaded up to a value of £750, with the maximum redemption value of £250 per transaction.</p>
                                    <p>
                                      Sainsbury's also offer a Groceries eGift
                                      Card, which can be used in-store only. The
                                      Groceries eGift Card can not be redeemed
                                      on General Merchandise, Clothing, Beers,
                                      Wines and Spirits and other non-food
                                      products. To see the full list of excluded
                                      categories,{" "}
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://help.sainsburys.co.uk/help/products/about-sainsburys-giftcard"
                                      >
                                        click here
                                      </a>
                                      .
                                    </p>
                                    <p>
                                      Habitat.co.uk eGift Cards are now
                                      available to purchase and can be loaded
                                      with any value between £1.00 - £500.00.
                                      Habitat eGift Cards can only be used to
                                      purchase goods online at Habitat.co.uk.
                                    </p>
                                    <p>
                                      For full list of T&Cs, please{" "}
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://sainsburysforbusiness.co.uk/wp-content/uploads/2023/12/Non-Account-Gift-Card-Purchasing-Terms-October-2023.pdf"
                                      >
                                        click here
                                      </a>
                                      .
                                    </p>
                                    <p>
                                      Discounts are available on Gift Cards
                                      purchased in bulk. Please{" "}
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={this.loadDiscounts}
                                      >
                                        click here
                                      </span>{" "}
                                      to view the discounts available on
                                      Sainsbury's, Argos and Habitat Gift Cards.</p>
                                      <p>Please be advised from 26th June 2024, discounts available on bulk purchasing will be changing. For Gift Card orders over £100,000 or other discount enquiries please
                                      email us at{" "}
                                      <a href="mailto:afb.giftcards@sainsburys.co.uk">
                                        afb.giftcards@sainsburys.co.uk
                                      </a>
                                    </p>

                                    <div
                                      className="ln-c-form-option ln-c-form-option--checkbox"
                                      onClick={this.onToggleIsBusiness}
                                    >
                                      <input
                                        id="checkbox-field-1-gluten"
                                        name="checkbox-field-1"
                                        type="checkbox"
                                        className="ln-c-form-option__input"
                                        checked={this.state.isBusiness}
                                      />
                                      <label
                                        className="ln-c-form-option__label"
                                        htmlFor="checkbox-field-1-gluten"
                                      >
                                        <span>
                                          This order site is for businesses,
                                          local authorities, charities and
                                          schools only.
                                          <br />
                                          Please tick here to confirm you are a
                                          business and to proceed with your Gift
                                          Card order
                                        </span>
                                      </label>
                                    </div>

                                    {this.state.isBusiness && (
                                      <div style={{ paddingTop: 80 }}>
                                        Build your order by choosing a Brand and
                                        then a Gift Card below. Multiple Brands
                                        and Gift Cards can be added to a single
                                        order.
                                      </div>
                                    )}

                                    {this.state.orderItems.length > 0 && (
                                      <div className="row">
                                        <div className="column small-12 text-center">
                                          <div className="button-container">
                                            <table className="ln-c-table">
                                              <thead className="ln-c-table__header">
                                                <tr className="ln-c-table__row ln-c-table__header-row">
                                                  <th className="ln-c-table__header-cell customCol">
                                                    Gift Card Type
                                                  </th>
                                                  <th className="ln-c-table__header-cell customCol">
                                                    Quantity
                                                  </th>
                                                  <th className="ln-c-table__header-cell customCol">
                                                    Gift Card Value
                                                  </th>
                                                  <th className="ln-c-table__header-cell customCol">
                                                    &nbsp;
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody className="ln-c-table__body">
                                                {this.state.orderItems.map(
                                                  (item, index) => (
                                                    <tr
                                                      key={index}
                                                      className="ln-c-table__row "
                                                    >
                                                      <td className="customCol ln-c-table__cell">
                                                        {item.itemName}
                                                      </td>
                                                      <td className="customCol ln-c-table__cell">
                                                        {item.qty}
                                                      </td>
                                                      <td className="customCol ln-c-table__cell">
                                                        &pound;
                                                        {item.value.toFixed(2)}
                                                      </td>
                                                      <td className="text-right">
                                                        {item.itemType === 1 &&
                                                          this.state.orderItems.filter(
                                                            (d) =>
                                                              d.itemType ===
                                                                3 &&
                                                              d.lineId ===
                                                                item.lineId
                                                          ).length === 0 && (
                                                            <div
                                                              className="ln-c-button ln-c-button--filled"
                                                              onClick={() =>
                                                                this.addWallets(
                                                                  item.lineId,
                                                                  3,
                                                                  item.qty
                                                                )
                                                              }
                                                              style={{
                                                                marginRight: 10,
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              Add Wallets
                                                            </div>
                                                          )}
                                                        {(item.itemType === 5 ||
                                                          item.itemType === 7 ||
                                                          item.itemType === 8 ||
                                                          item.itemType ===
                                                            9) &&
                                                          this.state.orderItems.filter(
                                                            (d) =>
                                                              d.itemType ===
                                                                6 &&
                                                              d.lineId ===
                                                                item.lineId
                                                          ).length === 0 && (
                                                            <div
                                                              className="ln-c-button ln-c-button--filled"
                                                              onClick={() =>
                                                                this.addWallets(
                                                                  item.lineId,
                                                                  6,
                                                                  item.qty
                                                                )
                                                              }
                                                              style={{
                                                                marginRight: 10,
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              Add Wallets
                                                            </div>
                                                          )}
                                                        <div
                                                          className="ln-c-button ln-c-button--outlined delete-item"
                                                          onClick={(e) => {
                                                            if (
                                                              window.confirm(
                                                                "Are you sure you wish to delete this item?"
                                                              )
                                                            )
                                                              this.deleteRow(
                                                                index
                                                              );
                                                          }}
                                                        >
                                                          Remove
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {this.state.isBusiness && (
                                      <div
                                        className="row"
                                        style={{ paddingTop: 40 }}
                                      >
                                        <div className="column small-12 medium-6">
                                          <SelectBox
                                            name="cardBrand"
                                            label={
                                              this.state.orderItems.length > 0
                                                ? "Choose Brand to add more Gift Cards to your order"
                                                : "Choose Brand"
                                            }
                                            value={this.state.cardBrand}
                                            maxLength={45}
                                            options={this.state.cardBrands}
                                            onChange={this.onChange.bind(this)}
                                            longLabel={true}
                                          />
                                        </div>
                                      </div>
                                    )}

                                    <div className="row">
                                      {this.state.cardBrand !== "" && (
                                        <div className="column small-12 medium-6">
                                          <SelectBox
                                            name="cardType"
                                            label="Choose Gift Card"
                                            value={this.state.cardType}
                                            maxLength={45}
                                            options={
                                              this.state.cardBrand === "argos"
                                                ? this.state.cardOptionsArgos
                                                : this.state.cardBrand ===
                                                  "habitat"
                                                ? this.state.cardOptionsHabitat
                                                : this.state.cardBrand === "multi"
                                                ? this.state.cardOptionsMulti
                                                : this.state
                                                    .cardOptionsSainsburys
                                            }
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errOrderItems}
                                          />
                                        </div>
                                      )}

                                      {this.state.cardType !== "" && (
                                        <div className="column small-12 medium-2 input-height">
                                          <TextInput
                                            name="cardQuantity"
                                            type="number"
                                            label="Quantity"
                                            value={this.state.cardQuantity}
                                            pattern={"[0-9]"}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errCardQuantity}
                                          />
                                        </div>
                                      )}

                                      {(this.state.cardType === "1" ||
                                        this.state.cardType === "2" ||
                                        this.state.cardType === "7" ||
                                        this.state.cardType === "8" ||
                                        this.state.cardType === "10" ||
                                        this.state.cardType === "11" ||
                                        this.state.cardType === "12" ||
                                        this.state.cardType === "13" ||
                                        this.state.cardType === "17") && (
                                        <div className="column small-12 medium-2 input-height">
                                          <CurrencyInput
                                            name="cardValue"
                                            type="text"
                                            placeholder=""
                                            label="Card Value"
                                            value={this.state.cardValue}
                                            format="currency"
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errCardValue}
                                          />
                                        </div>
                                      )}

                                      {this.state.cardType !== "" && (
                                        <div className="column small-12 medium-2">
                                          <div className="ln-c-form-group">
                                            <label className="ln-c-label">
                                              &nbsp;
                                            </label>
                                            <div className="ln-c-input-group">
                                              <SubmitButton
                                                text="Add"
                                                full={true}
                                                onClick={this.addCard}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    {this.state.apiError && (
                                      <div className="row">
                                        <div className="column small-12 text-center ln-c-form-group has-error">
                                          <div
                                            id="form-group-5Validation"
                                            className="ln-c-field-info ln-c-field-info--error error-block"
                                            style={{ color: "#fff" }}
                                            role="alert"
                                          >
                                            There has been a problem continuing
                                            with your order. Please wait a while
                                            and try again.
                                            <br />
                                            If the problem persists, please
                                            contact the Gifting Account Support
                                            team at{" "}
                                            <a href="mailto:afb.giftcards@sainsburys.co.uk">
                                              afb.giftcards@sainsburys.co.uk
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {this.state.orderItems.length > 0 &&
                                      this.state.isBusiness && (
                                        <div className="row">
                                          <div className="column small-12 text-center">
                                            <div className="button-container">
                                              <SubmitButton
                                                disabled={this.state.loading}
                                                text={
                                                  this.state.loading
                                                    ? "Loading..."
                                                    : "Proceed to step 2"
                                                }
                                                onClick={this.step2}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.page === 2 && (
                    <div>
                      <div className="order-form-wrap">
                        <div className="row">
                          <div className="small-12">
                            <div className="order-form">
                              <div className="row">
                                <div className="small-12">
                                  <div className="content-panel">
                                    <h3 className="header h3">
                                      Step 2 – Discount and Delivery charge
                                      applied
                                    </h3>

                                    <table className="ln-c-table">
                                      <thead className="ln-c-table__header">
                                        <tr className="ln-c-table__row ln-c-table__header-row">
                                          <th className="ln-c-table__header-cell customCol">
                                            Gift Card Type
                                          </th>
                                          <th
                                            className="ln-c-table__header-cell customCol"
                                            style={{ textAlign: "right" }}
                                          >
                                            Quantity
                                          </th>
                                          <th
                                            className="ln-c-table__header-cell customCol"
                                            style={{ textAlign: "right" }}
                                          >
                                            Denomination
                                          </th>
                                          <th
                                            className="ln-c-table__header-cell customCol"
                                            style={{ textAlign: "right" }}
                                          >
                                            Total Amount
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="ln-c-table__body">
                                        {this.state.orderItems.map(
                                          (item, index) => (
                                            <tr
                                              key={index}
                                              className="ln-c-table__row"
                                            >
                                              <td className="customCol ln-c-table__cell">
                                                {item.itemName}
                                              </td>
                                              <td
                                                className="customCol ln-c-table__cell"
                                                style={{ textAlign: "right" }}
                                              >
                                                {item.qty}
                                              </td>
                                              <td
                                                className="customCol ln-c-table__cell"
                                                style={{ textAlign: "right" }}
                                              >
                                                &pound;{item.value.toFixed(2)}
                                              </td>
                                              <td
                                                className="customCol ln-c-table__cell"
                                                style={{ textAlign: "right" }}
                                              >
                                                &pound;
                                                {(
                                                  item.qty * item.value
                                                ).toFixed(2)}
                                              </td>
                                            </tr>
                                          )
                                        )}

                                        {this.state.orderItems.filter(
                                          (d) =>
                                            d.itemType === 1 ||
                                            d.itemType === 5 ||
                                            d.itemType === 7 ||
                                            d.itemType === 8 ||
                                            d.itemType === 9
                                        ).length > 0 && (
                                          <tr className="ln-c-table__row">
                                            <td className="customCol ln-c-table__cell">
                                              Delivery Charge
                                            </td>
                                            <td
                                              className="customCol ln-c-table__cell"
                                              style={{ textAlign: "right" }}
                                            >
                                              1
                                            </td>
                                            <td
                                              className="customCol ln-c-table__cell"
                                              style={{ textAlign: "right" }}
                                            >
                                              &pound;5.95
                                            </td>
                                            <td
                                              className="customCol ln-c-table__cell"
                                              style={{ textAlign: "right" }}
                                            >
                                              &pound;5.95
                                            </td>
                                          </tr>
                                        )}

                                        {this.state.orderItems.length > 0 && (
                                          <tr className="ln-c-table__row table-summary-row">
                                            <td
                                              className="customCol ln-c-table__cell"
                                              colSpan={3}
                                              style={{ textAlign: "right" }}
                                            >
                                              Total
                                            </td>
                                            <td
                                              className="customCol ln-c-table__cell"
                                              style={{ textAlign: "right" }}
                                            >
                                              &pound;
                                              {this.state.orderItems.some(
                                                (d) =>
                                                  d.itemType === 1 ||
                                                  d.itemType === 5 ||
                                                  d.itemType === 7 ||
                                                  d.itemType === 8 ||
                                                  d.itemType === 9
                                              )
                                                ? parseFloat(
                                                    sum + 5.95
                                                  ).toFixed(2)
                                                : sum.toFixed(2)}
                                            </td>
                                          </tr>
                                        )}
                                        {argosDiscount +
                                          sainsburysDiscount +
                                          habitatDiscount + multiDiscount >
                                          0 && (
                                          <tr className="ln-c-table__row table-summary-row">
                                            <td
                                              className="customCol ln-c-table__cell"
                                              colSpan={3}
                                              style={{ textAlign: "right" }}
                                            >
                                              Discount
                                            </td>
                                            <td
                                              className="customCol ln-c-table__cell"
                                              style={{ textAlign: "right" }}
                                            >
                                              &pound;
                                              {(
                                                argosDiscount +
                                                sainsburysDiscount +
                                                habitatDiscount + multiDiscount
                                              ).toFixed(2)}
                                            </td>
                                          </tr>
                                        )}
                                        {this.state.orderItems.length > 0 && (
                                          <tr className="ln-c-table__row table-summary-row">
                                            <td
                                              className="customCol ln-c-table__cell"
                                              colSpan={3}
                                              style={{ textAlign: "right" }}
                                            >
                                              <strong>Total Cost</strong>
                                            </td>
                                            <td
                                              className="customCol ln-c-table__cell"
                                              style={{ textAlign: "right" }}
                                            >
                                              <strong>
                                                &pound;
                                                {this.state.orderItems.some(
                                                  (d) =>
                                                    d.itemType === 1 ||
                                                    d.itemType === 5 ||
                                                    d.itemType === 7 ||
                                                    d.itemType === 8 ||
                                                    d.itemType === 9
                                                )
                                                  ? parseFloat(
                                                      parseFloat(
                                                        discountedTotal
                                                      ) + 5.95
                                                    ).toFixed(2)
                                                  : parseFloat(
                                                      discountedTotal
                                                    ).toFixed(2)}
                                              </strong>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>

                                    <div className="row">
                                      <div className="column small-12 text-center">
                                        <div className="button-container">
                                          <SubmitButton
                                            text="Back to step 1"
                                            secondary={true}
                                            onClick={() => this.back(1)}
                                          />
                                          <SubmitButton
                                            text={
                                              this.state.loading
                                                ? "Loading..."
                                                : "Proceed to step 3"
                                            }
                                            disabled={this.state.loading}
                                            onClick={this.step3}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.page === 3 && (
                    <div>
                      <div className="row order-form-wrap">
                        <div className="small-12">
                          <div className="order-form">
                            <div className="row">
                              <div className="small-12">
                                <div className="content-panel">
                                  <h3 className="header h3">
                                    Step 3 - Your Details
                                  </h3>
                                  <p>
                                    Please input your company and address
                                    details. Mandatory fields are marked with an
                                    asterisk. Please note this website is not
                                    transactional. Once your order has been
                                    processed, we will send you an email with
                                    details on how to make payment. If you don’t
                                    receive the email, please check your junk
                                    email folder or contact the team via email
                                    at{" "}
                                    <a href="mailto:afb.giftcards@sainsburys.co.uk">
                                      afb.giftcards@sainsburys.co.uk
                                    </a>{" "}
                                    and we will resend.
                                  </p>
                                  <p>
                                    eGift Cards are delivered to the email
                                    address provided within 1 working day of
                                    payment being received. eGift Cards will be
                                    emailed to your email address protected by a
                                    password which shall be sent to you via text
                                    message. Please ensure these details are
                                    accurate before submitting your order.
                                  </p>
                                  <p>
                                    Physical Gift Cards are despatched to the
                                    postal address provided within 2 working
                                    days of payment being received.
                                    Unfortunately, we can not facilitate special
                                    delivery.
                                  </p>
                                  <p>
                                    This order form is for businesses only. If
                                    you are not a business and are looking to
                                    order a single Gift Card or eGift Card
                                    please{" "}
                                    <a href="https://sainsburysgiftcard.co.uk/">
                                      click here
                                    </a>
                                    .
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row order-form-wrap">
                        <div className="small-12">
                          <div className="order-form">
                            <div className="row">
                              <div className="small-12">
                                <div className="content-panel">
                                  <h3 className="header h3">Billing Address</h3>

                                  <div className="row">
                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="contactName"
                                        type="text"
                                        label="Your Name"
                                        value={this.state.contactName}
                                        maxLength={150}
                                        validation={"clean"}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errContactName}
                                        asterix={true}
                                      />
                                    </div>
                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="clientName"
                                        type="text"
                                        label="Organisation/Company Name"
                                        value={this.state.clientName}
                                        maxLength={150}
                                        validation={"clean"}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errClientName}
                                        asterix={true}
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="contactEmail"
                                        type="email"
                                        label="Your Email Address"
                                        value={this.state.contactEmail}
                                        maxLength={150}
                                        validation={"clean"}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errContactEmail}
                                        asterix={true}
                                      />
                                    </div>
                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="contactTel"
                                        type="tel"
                                        label="Your Mobile Number"
                                        value={this.state.contactTel}
                                        maxLength={11}
                                        validation={"numeric"}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errContactTel}
                                        asterix={true}
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="contactLine1"
                                        type="text"
                                        label="Address line 1"
                                        value={this.state.contactLine1}
                                        maxLength={200}
                                        validation={"clean"}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errContactLine1}
                                        asterix={true}
                                      />
                                    </div>
                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="contactLine2"
                                        type="text"
                                        label="Address line 2"
                                        value={this.state.contactLine2}
                                        maxLength={200}
                                        validation={"clean"}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errContactLine2}
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="contactTown"
                                        type="text"
                                        label="Town/City"
                                        value={this.state.contactTown}
                                        maxLength={200}
                                        validation={"clean"}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errContactTown}
                                        asterix={true}
                                      />
                                    </div>
                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="contactPostcode"
                                        type="text"
                                        label="Post Code"
                                        value={this.state.contactPostcode}
                                        maxLength={50}
                                        validation={"clean"}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errContactPostcode}
                                        asterix={true}
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="column small-12 medium-6">
                                      <SelectBox
                                        name="contactCountry"
                                        label="Country"
                                        value={this.state.contactCountry}
                                        maxLength={45}
                                        options={[
                                          {
                                            value: "",
                                            label: "Select...",
                                          },
                                          {
                                            value: "GB",
                                            label: "United Kingdom",
                                          },
                                        ]}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errContactCountry}
                                        disabled={true}
                                        asterix={true}
                                      />
                                    </div>

                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="charityNumber"
                                        type="text"
                                        label="Company / Charity number"
                                        value={this.state.charityNumber}
                                        maxLength={50}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errCharityNumber}
                                        validation={"strong"}
                                        asterix={false}
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="column small-12 medium-6">
                                      <TextInput
                                        name="clientPO"
                                        type="text"
                                        label="PO/Reference"
                                        value={this.state.clientPO}
                                        maxLength={25}
                                        validation={"strong"}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errClientPO}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row order-form-wrap">
                        <div className="small-12">
                          <div className="order-form">
                            <div className="row">
                              <div className="small-12">
                                <div className="content-panel">
                                  <h3 className="header h3">
                                    Delivery Address
                                  </h3>

                                  <div className="row">
                                    <div className="column small-12 medium-6">
                                      <SwitchButton
                                        text="Same as billing address"
                                        name="copyAddress"
                                        value={this.state.copyAddress}
                                        onChange={this.onChange.bind(this)}
                                      />
                                    </div>
                                  </div>

                                  {!this.state.copyAddress && (
                                    <div>
                                      <div className="row">
                                        <div className="column small-12 medium-6">
                                          <TextInput
                                            name="recipientName"
                                            type="text"
                                            label="Recipient Name"
                                            value={this.state.recipientName}
                                            maxLength={150}
                                            validation={"clean"}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errRecipientName}
                                            asterix={true}
                                          />
                                        </div>
                                        <div className="column small-12 medium-6">
                                          <TextInput
                                            name="recipientCompany"
                                            type="text"
                                            label="Organisation/Company Name"
                                            value={this.state.recipientCompany}
                                            maxLength={150}
                                            validation={"clean"}
                                            onChange={this.onChange.bind(this)}
                                            error={
                                              this.state.errRecipientCompany
                                            }
                                            asterix={true}
                                          />
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="column small-12 medium-6">
                                          <TextInput
                                            name="recipientEmail"
                                            type="email"
                                            label="Recipient Email Address"
                                            value={this.state.recipientEmail}
                                            maxLength={150}
                                            validation={"clean"}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errRecipientEmail}
                                            asterix={true}
                                          />
                                        </div>
                                        <div className="column small-12 medium-6">
                                          <TextInput
                                            name="recipientTel"
                                            type="tel"
                                            label="Recipient Mobile Number"
                                            value={this.state.recipientTel}
                                            maxLength={11}
                                            validation={"numeric"}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errRecipientTel}
                                            asterix={true}
                                          />
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="column small-12 medium-6">
                                          <TextInput
                                            name="recipientLine1"
                                            type="text"
                                            label="Address line 1"
                                            value={this.state.recipientLine1}
                                            maxLength={200}
                                            validation={"clean"}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errRecipientLine1}
                                            asterix={true}
                                          />
                                        </div>
                                        <div className="column small-12 medium-6">
                                          <TextInput
                                            name="recipientLine2"
                                            type="text"
                                            label="Address line 2"
                                            value={this.state.recipientLine2}
                                            maxLength={200}
                                            validation={"clean"}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errRecipientLine2}
                                          />
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="column small-12 medium-6">
                                          <TextInput
                                            name="recipientTown"
                                            type="text"
                                            label="Town/City"
                                            value={this.state.recipientTown}
                                            maxLength={200}
                                            validation={"clean"}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errRecipientTown}
                                            asterix={true}
                                          />
                                        </div>
                                        <div className="column small-12 medium-6">
                                          <TextInput
                                            name="recipientPostcode"
                                            type="text"
                                            label="Post Code"
                                            value={this.state.recipientPostcode}
                                            maxLength={50}
                                            validation={"clean"}
                                            onChange={this.onChange.bind(this)}
                                            error={
                                              this.state.errRecipientPostcode
                                            }
                                            asterix={true}
                                          />
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="column small-12 medium-6">
                                          <SelectBox
                                            name="recipientCountry"
                                            label="Country"
                                            value={this.state.recipientCountry}
                                            maxLength={45}
                                            options={[
                                              {
                                                value: "",
                                                label: "Select...",
                                              },
                                              {
                                                value: "GB",
                                                label: "United Kingdom",
                                              },
                                            ]}
                                            onChange={this.onChange.bind(this)}
                                            disabled={true}
                                            error={
                                              this.state.errRecipientCountry
                                            }
                                            asterix={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div
                                    className="row"
                                    style={{ paddingTop: 30 }}
                                  >
                                    <div className="column small-12 medium-6">
                                      <SelectBox
                                        name="hearUs"
                                        label="How did you hear about us?"
                                        value={this.state.hearUs}
                                        maxLength={45}
                                        options={[
                                          {
                                            value: "",
                                            label: "Select...",
                                          },
                                          {
                                            value: "existing",
                                            label:
                                              "Existing Sainsbury's for Business Customer",
                                          },
                                          {
                                            value: "recommendation",
                                            label: "Recommendation",
                                          },
                                          {
                                            value: "nectar",
                                            label: "Nectar for Business",
                                          },
                                          {
                                            value: "search",
                                            label: "Search Engine",
                                          },
                                          {
                                            value: "other",
                                            label:
                                              "Other - Please provide information",
                                          },
                                        ]}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errHearUs}
                                        asterix={true}
                                      />
                                    </div>
                                    {this.state.hearUs === "other" && (
                                      <div className="column small-12 medium-6">
                                        <TextInput
                                          name="hearUsOther"
                                          type="text"
                                          label="Please explain how you heard about us?"
                                          value={this.state.hearUsOther}
                                          maxLength={250}
                                          onChange={this.onChange.bind(this)}
                                          error={this.state.errHearUsOther}
                                          asterix={true}
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <div className="row">
                                    <div className="column small-12">
                                      <div
                                        className="switch-wrap"
                                        style={{ paddingTop: 30 }}
                                      >
                                        <p className="no-pad">
                                          By placing this order, you confirm
                                          that you have read and agreed to the{" "}
                                          <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="https://sainsburysforbusiness.co.uk/wp-content/uploads/2023/12/Non-Account-Gift-Card-Purchasing-Terms-October-2023.pdf"
                                          >
                                            terms and conditions
                                          </a>{" "}
                                          &amp; read and understand the{" "}
                                          <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="https://privacy-hub.sainsburys.co.uk/privacy-policy/"
                                          >
                                            privacy policy
                                          </a>
                                          .
                                        </p>
                                        <p className="no-pad">
                                          As a valued customer, we'd like to
                                          send you marketing by email from
                                          Sainsbury's Argos Giftcard. If you
                                          don't want us to do so then please
                                          tick the box below.
                                        </p>

                                        <div
                                          className="ln-c-form-option ln-c-form-option--checkbox"
                                          onClick={this.onToggleTerms}
                                        >
                                          <input
                                            id="checkbox-field-1-gluten"
                                            name="checkbox-field-1"
                                            type="checkbox"
                                            className="ln-c-form-option__input"
                                            checked={this.state.terms}
                                          />
                                          <label
                                            className="ln-c-form-option__label"
                                            htmlFor="checkbox-field-1-gluten"
                                          >
                                            <span>
                                              I agree to the Terms and
                                              Conditions and understand the
                                              Privacy Policy
                                            </span>
                                          </label>
                                        </div>
                                        {this.state.errTerms && (
                                          <div className="ln-c-form-group has-error">
                                            <div
                                              id="checkbox-field-5Validation"
                                              className="ln-c-field-info ln-c-field-info--error"
                                              role="alert"
                                            >
                                              {this.state.errTerms}
                                            </div>
                                          </div>
                                        )}
                                        <div
                                          className="ln-c-form-option ln-c-form-option--checkbox"
                                          onClick={this.onToggleMarketing}
                                        >
                                          <input
                                            id="checkbox-field-2-gluten"
                                            name="checkbox-field-1"
                                            type="checkbox"
                                            className="ln-c-form-option__input"
                                            checked={this.state.optOut}
                                          />
                                          <label
                                            className="ln-c-form-option__label"
                                            htmlFor="checkbox-field-1-gluten"
                                          >
                                            <span>
                                              Please tick this box if you don't
                                              want to receive Business to
                                              Business marketing from
                                              Sainsbury's Argos Gift Card
                                              (encompassing Argos for Business
                                              and Sainsburys for Business,
                                              trading names of Argos Business
                                              Solutions).
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="column small-12 text-center">
                                      <ReCaptcha
                                        ref={(el) => {
                                          this.captchaDemo = el;
                                        }}
                                        size="normal"
                                        render="explicit"
                                        sitekey="6LfRXtwZAAAAAKB20Uxnx34FIhvQ7N7b3ryAmPRN"
                                        onloadCallback={this.onLoadRecaptcha}
                                        verifyCallback={this.verifyCallback}
                                      />
                                      {this.state.errCaptcha && (
                                        <div className="ln-c-form-group has-error">
                                          <div
                                            id="checkbox-field-5Validation"
                                            className="ln-c-field-info ln-c-field-info--error"
                                            role="alert"
                                          >
                                            {this.state.errCaptcha}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {this.state.hasErrors && (
                                    <div className="row">
                                      <div className="column small-12 text-center ln-c-form-group has-error">
                                        <div
                                          id="form-group-5Validation"
                                          className="ln-c-field-info ln-c-field-info--error"
                                          role="alert"
                                        >
                                          Please check the errors above.
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {this.state.apiError && (
                                    <div className="row">
                                      <div className="column small-12 text-center ln-c-form-group has-error">
                                        <div
                                          id="form-group-5Validation"
                                          className="ln-c-field-info ln-c-field-info--error error-block"
                                          style={{ color: "#fff" }}
                                          role="alert"
                                        >
                                          {this.state.apiError === "failed" ? (
                                            <>
                                              There has been a problem creating
                                              your order. Please wait a while
                                              and try again.
                                              <br />
                                              If the problem persists, please
                                              contact the Gifting Account
                                              Support team at{" "}
                                              <a href="mailto:afb.giftcards@sainsburys.co.uk">
                                                afb.giftcards@sainsburys.co.uk
                                              </a>
                                            </>
                                          ) : (
                                            this.state.apiError
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div className="row">
                                    <div className="column small-12 text-center">
                                      <div className="button-container">
                                        <SubmitButton
                                          text="Back to step 2"
                                          secondary={true}
                                          onClick={() => this.back(2)}
                                        />
                                        <SubmitButton
                                          text={
                                            this.state.loading
                                              ? "Loading..."
                                              : "Submit order"
                                          }
                                          disabled={this.state.loading}
                                          onClick={this.save}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />

        {this.state.showDiscounts && (
          <>
            <div className="modal-shadow"></div>
            <div className="incident-modal">
              <div className="ms-close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  onClick={this.hideDiscounts}
                >
                  <path
                    fillRule="evenodd"
                    d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div>
                <table className="ln-c-table">
                  <thead className="ln-c-table__header">
                    <tr className="ln-c-table__row ln-c-table__header-row">
                      <th className="ln-c-table__header-cell customCol">
                        Brand
                      </th>
                      <th className="ln-c-table__header-cell customCol">
                        Purchases From
                      </th>
                      <th className="ln-c-table__header-cell customCol">
                        Discount Rate
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ln-c-table__body">
                    {this.state.discounts.map((item, index) => (
                      <tr key={index} className="ln-c-table__row">
                        <td className="customCol ln-c-table__cell">
                          {item.Brand}
                        </td>
                        <td className="customCol ln-c-table__cell">
                          {item.appliesFrom === 0
                            ? `Below £${item.appliesTo.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}`
                            : item.appliesTo === 0
                            ? `£${item.appliesFrom.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}+`
                            : `£${item.appliesFrom.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })} to £${item.appliesTo.toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}`}
                        </td>
                        <td className="customCol ln-c-table__cell">
                          {item.DiscountRate}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default OrderPage;
