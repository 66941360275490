import React from 'react';
import PropTypes from 'prop-types';

class TextInput extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.url && this.props.button) {
            console.warn('Both a url and button prop were passed to the TextInput component');
        }
    }

    onFieldChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    validateInput(e) {
        if (this.props.pattern) {
            const re = RegExp(this.props.pattern);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }


        // var regex = new RegExp("^[a-zA-Z0-9.,/ $@()]+$");

        if (this.props.validation === "clean") {
            const re = RegExp(/[a-zA-Z0-9.,/ '@()&._-]+/g);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }

        if (this.props.validation === "strong") {
            const re = RegExp(/^(?!.*--)[a-zA-Z0-9.,/._-]$/g);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }

        if (this.props.validation === "numeric") {
            const re = RegExp(/[0-9]+/g);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }

        if (this.props.alhpaNumeric || this.props.validation === "alhpaNumeric") {
            const re = RegExp(/[0-9A-Za-z]+/g);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }
    }

    render() {
        return (
            <div className={this.props.error ? 'ln-c-form-group has-error' : 'ln-c-form-group'}>
                {this.props.label ? <label htmlFor={this.props.name} className="ln-c-label">{this.props.label}{this.props.asterix ? <span className="asterix">*</span> : <span className="asterix">&nbsp;</span>}</label> : ''}
                {this.props.error ? <div id="text-input-field-2Validation" className="ln-c-field-info ln-c-field-info--error" role="alert">{this.props.error}</div> : ''}
                <div className="ln-c-input-group">
                    <input
                        id={this.props.name}
                        type={this.props.type ? this.props.type : "text"}
                        name={`${this.props.name}`}
                        placeholder={this.props.placeholder}
                        onChange={this.onFieldChange.bind(this)}
                        maxLength={this.props.maxLength ? this.props.maxLength : 200}
                        value={this.props.toUpper && this.props.value ? this.props.value.toUpperCase() : this.props.value}
                        className="ln-c-text-input ln-c-input-group__control"
                        onKeyPress={(e) => this.validateInput(e)}
                        autoComplete={`auto-${this.props.name}`}
                        pattern={this.props.pattern ? this.props.pattern : null}
                        disabled={this.props.disabled ? this.props.disabled : false}
                        step={0.01}
                        min={0}
                    />
                </div>
            </div>
        )
    }

};

TextInput.propTypes = {
    alhpaNumeric: PropTypes.bool,
    button: PropTypes.func,
    url: PropTypes.string,
    error: PropTypes.string,
    link: PropTypes.string,
    class: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    toUpper: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default TextInput;