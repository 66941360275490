import APIUtils from "./APIUtils";

async function apiRequest({ endpoint = '', method = 'GET', payload = null, stringify = true }, cb) {
    const url = `${APIUtils.getBaseUrl()}${endpoint}`
    APIUtils.getHeaders(async (headers) => {
        if (!stringify) {
            headers.append('X-Requested-With', 'XMLHttpRequest');
        }

        try {

            const response = await fetch(url, {
                method,
                headers,
                ...(payload != null ? { body: stringify ? JSON.stringify(payload) : payload } : {}),
            });

            const json = await response.json();
            cb(json);


        } catch (e) {
            console.log("error", e);
            cb(null);
        }


    });
}



export default apiRequest;