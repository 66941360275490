import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import "./App.css";
import Routes from './config/routes';
import { loadReCaptcha } from 'react-recaptcha-google'

class App extends Component {

  componentDidMount() {
    loadReCaptcha();
  }

  render() {

    return (
      <div>
        <Routes {...this.props} />
      </div>
    )
  }

}

export default withRouter((App));