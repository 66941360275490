import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

class SwitchButton extends React.Component {

    handleSwitch(name, value) {
        this.props.onChange(name, !value);
    }

    render() {
        return (
            <div className="switch-button-group">
                {this.props.label && <label htmlFor={this.props.name}>{this.props.label}{this.props.asterix ? <span className="asterix">*</span> : ''}</label>}
                <div style={{ paddingTop: 5 }}>
                    <div style={{ float: 'left', marginRight: 5 }}>
                        <Switch
                            onChange={() => this.handleSwitch(this.props.name, this.props.value)}
                            checked={this.props.value}
                            id="normal-switch"
                            className='switch'
                            offColor="#fff"
                            onColor="#F06C00"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={this.props.height ? this.props.height : 28}
                            width={this.props.width ? this.props.width : 56}
                            disabled={this.props.disabled ? this.props.disabled : false}
                        />
                    </div>
                    {this.props.text && <span style={{ float: 'left' }}>{this.props.text}</span>}
                </div>
            </div>
        )
    }

};

SwitchButton.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default SwitchButton;