import apiRequest from '.';

class ConsoleAPI {

    updOneOffOrder(payload, cb) {
        apiRequest({ endpoint: `console/oneofforder`, method: 'POST', payload }, cb);
    }

    validateOrder(payload, cb) {
        apiRequest({ endpoint: `console/validateorder`, method: 'POST', payload }, cb);
    }

    unsubscribeUser(id, cb) {
        apiRequest({ endpoint: `console/unsubscribe?id=${id}`, method: 'PUT', id }, cb);
    }

    getOrderTracking(id, cb) {
        apiRequest({ endpoint: `console/ordertracking?id=${id}` }, cb);
    }

    getDiscounts(cb) {
        apiRequest({ endpoint: `console/discounts` }, cb);
    }

}

export default new ConsoleAPI();