import React from 'react';

class SelectBox extends React.Component {

    onSelectChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    render() {
        return (
            <div className={this.props.error ? 'ln-c-form-group has-error' : 'ln-c-form-group'}>
                {this.props.label ? <label htmlFor={this.props.name} className="ln-c-label">{this.props.label}{this.props.asterix ? <span className="asterix">*</span> : ''}&nbsp;</label> : ''}
                {this.props.error ? <div id="select-field-2Validation" className="ln-c-field-info ln-c-field-info--error" role="alert">{this.props.error}</div> : ''}
                <select 
                    aria-describedby="select-field-2Validation"
                    className="ln-c-select"
                    aria-invalid="true"
                    id={this.props.name}
                    name={this.props.name} 
                    value={this.props.value} 
                    onChange={this.onSelectChange.bind(this)}
                    autoComplete="off"
                    disabled={this.props.disabled ? this.props.disabled : false}
                    style={this.props.longLabel && {display: "block", width: "300px"}}
                >
                    {this.props.placeholder &&
                    <option value="">{this.props.placeholder}</option>
                    }
                    {this.props.options.map((option, index) => 
                        <option key={index} value={option.value}>{option.label}</option>
                    )}
                </select>
                
            </div>
        )
    }

};

export default SelectBox;