import 'react-app-polyfill/ie11';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import { unregister } from "./registerServiceWorker";
import App from "./App";
import "./scss/gate.css";
import 'react-app-polyfill/ie9';

ReactDOM.render(
  <Router onUpate={() => window.scrollTo(0, 0)}>
    {/*<HttpsRedirect>*/}
    <App />
    {/*</HttpsRedirect>*/}
  </Router>,
  document.getElementById("root")
);
unregister();